<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Monitor from "@/services/Monitor";
import Swal from "sweetalert2";
import DatePicker from "vue2-datepicker";
import moment from "moment";
import Multiselect from 'vue-multiselect';
import 'vue-multiselect/dist/vue-multiselect.min.css';

export default {
    components: { Layout, PageHeader, DatePicker, Multiselect, },
    page: {
        title: "Monitor",
        meta: [
            {
                name: "description",
                content: appConfig.description,
            },
        ],
    },
    watch: {
        'filterInput': {
            handler: function () {
                this.filterClicked = false;
            },
            deep: true
        }
    },
    data() {
        return {
            title: "Monitor",
            items: [
                {
                    text: "Monitor",
                    active: true,
                },
            ],
            filterInput: {
                server: '',
                key: [],
                dateFrom: null,
                dateTo: null,
            },
            serverOptions: [],
            keyOptions: [],
            error: null,
            showModal: false,
            modalData: {},
            tableData: [],
            showErrorMessage: false,
            filterClicked: false,
            totalRows: 0,
            currentPage: 1,
            perPage: 10,
            pageOptions: [10, 25, 50, 100],
            filter: null,
            filterOn: ['server', 'key', 'date_to', 'date_from'],
            sortBy: "server",
            showTable: false,
            sortDesc: true,
            fields: [
                {
                    key: "server",
                    label: "Server",
                    sortable: true,
                },
                {
                    key: "key",
                    label: "Key",
                    sortable: false,
                },
                {
                    key: "value",
                    label: "Value",
                    sortable: false,
                },
                {
                    key: "measure_time",
                    label: "Measure Time",
                    sortable: false,
                    formatter: (value) => {
                        return moment.unix(value).format('YYYY-MM-DD');
                    }
                },
            ],
            isBusy: false,
            isGettingKeys: false,

        };
    },
    computed: {
        isFormValid() {
            return (
                (this.filterInput.server && this.filterInput.server.trim() !== '') ||
                (this.filterInput.key && this.filterInput.key.length > 0) ||
                this.filterInput.dateFrom ||
                this.filterInput.dateTo
            );
        },
        rows() {
            return this.tableData.length;
        },
    },

    mounted() {
        this.totalRows = this.items.length;
        this.fetchDistinctServers();
    },

    methods: {
        async fetchDistinctServers() {
            try {
                const response = await Monitor.getMonitorPing();
                if (response.data.data && response.data.data.length) {
                    this.serverOptions = response.data.data.map(serverObj => serverObj.server);
                } else {
                    this.serverOptions = [];
                }
            } catch (error) {
                this.handleError(error, 'Error fetching distinct servers.');
            }
        },

        async onServerChange() {
            this.isGettingKeys = true;
            this.keyOptions = [];
            this.filterInput.key = [];
            try {
                const response = await Monitor.getMonitorPing({ server: this.filterInput.server });
                const selectedServer = response.data.data.find(s => s.server === this.filterInput.server);

                if (selectedServer && selectedServer.keys) {
                    this.keyOptions = selectedServer.keys.map(key => {
                        return {
                            key: key.key,
                            values: key.values.map(valueObj => ({
                                value: valueObj.value,
                                measure_time: valueObj.measure_time
                            }))
                        };
                    });
                }
            } catch (error) {
                this.handleError(error, 'Could not fetch keys for the selected server');
            } finally {
                this.isGettingKeys = false; 
            }
        },


        handleError(error, defaultMessage) {
            console.error(defaultMessage, error);
            let errorMessage = defaultMessage;
            if (error.response && error.response.data && error.response.data.error) {
                errorMessage = error.response.data.error;
            }
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: errorMessage,
            });
        },
        async getMonitorPing() {
            this.toggleBusy();
            try {
                this.toggleBusy();
                const filters = this.getFilters();
                const response = await Monitor.getMonitorPing(filters);

                if (response.data.error) {
                    this.toggleBusy();
                    this.handleError(response.data.error, 'Error fetching monitor ping.');
                    this.tableData = [];
                    return;
                }
                const flattenedData = [];
                if (response.data.data) {
                    response.data.data.forEach(serverObj => {
                        serverObj.keys.forEach(keyObj => {
                            keyObj.values.forEach(valueObj => {
                                flattenedData.push({
                                    server: serverObj.server,
                                    key: keyObj.key,
                                    value: valueObj.value,
                                    measure_time: valueObj.measure_time
                                });
                            });
                        });
                    });
                }
                this.tableData = flattenedData;
                this.totalRows = this.tableData.length;
                this.toggleTableVisibility();
                this.toggleBusy();
                // this.isBusy = false; 
            } catch (error) {
                this.toggleBusy();
                this.handleError(error, 'Error fetching monitor ping.');
                this.tableData = [];
            }
        },

        submitForm() {
            this.filterClicked = true;
            if (!this.filterInput.server) {
                this.showErrorMessage = true;
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Please select a server before filtering',
                });
                return;
            }
            this.toggleBusy();
            this.getMonitorPing();
            this.showErrorMessage = false;
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        toggleTableVisibility() {
            this.showTable = true; // Show the table
        },
        resetFilters() {
            this.filterInput = {
                server: '',
                key: [],
                dateFrom: '',
                dateTo: '',
            };
            this.keyOptions = [];
            this.showTable = false;
            this.filterClicked = false;

        },

        getFilters() {
            let filters = {};
            if (this.filterInput.server) {
                filters.server = this.filterInput.server;
            } else {
                return {};
            }

            if (this.filterInput.key && this.filterInput.key.length) {
                filters.key = this.filterInput.key.map(k => k.key).join(',');
            }

            if (this.filterInput.dateFrom && moment(this.filterInput.dateFrom).isValid()) {
                filters.date_from = moment(this.filterInput.dateFrom).format('YYYY-MM-DD');
            }

            if (this.filterInput.dateTo && moment(this.filterInput.dateTo).isValid()) {
                filters.date_to = moment(this.filterInput.dateTo).format('YYYY-MM-DD');
            }

            return new URLSearchParams(filters).toString();
        },

        toggleBusy() {
            this.isBusy = !this.isBusy;
        }
    },
    middleware: "authentication",
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row mt-4">
                            <div class="col-md-12">
                                <div class="custom-accordion">
                                    <a class="text-body fw-semibold pb-2 d-block" data-toggle="collapse"
                                        href="javascript: void(0);" role="button" aria-expanded="false"
                                        v-b-toggle.categories-collapse>
                                        <i class="mdi mdi-chevron-up accor-down-icon text-primary me-1"></i>Filters
                                    </a>
                                    <b-collapse visible id="categories-collapse">
                                        <div class="card p-2 border shadow-none">
                                            <div class="row">
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Server" label-for="formrow-name-input"
                                                        class="mb-3">
                                                        <b-form-select v-model="filterInput.server"
                                                            :options="serverOptions" id="server"
                                                            aria-describedby="name-feedback" class="form-select"
                                                            @change="onServerChange"></b-form-select>
                                                    </b-form-group>

                                                </div>
                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Key" label-for="formrow-series-input"
                                                        class="mb-3">
                                                        <multiselect v-model="filterInput.key" :options="keyOptions"
                                                            :multiple="true" :close-on-select="false"
                                                            :clear-on-select="false" :preserve-search="true"
                                                            placeholder="Select key(s)" label="key" track-by="key">
                                                          </multiselect>
                                                    </b-form-group>
                                                    <span v-if="isGettingKeys">
                                                                    <b-spinner small class="align-middle text-primary">Loading...</b-spinner>
                                                                    <span class="text-primary">Getting keys...</span>
                                                                   </span>
                                                </div>

                                                <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Date From" label-for="formrow-birthdate-input"
                                                        class="mb-3">
                                                        <date-picker v-model="filterInput.dateFrom" append-to-body
                                                            lang="en" confirm format="YYYY-MM-DD"></date-picker>
                                                    </b-form-group>

                                                </div>
                                        <div class="col-sm-12 col-md-3">
                                                    <b-form-group label="Date To" label-for="formrow-birthdate-input"
                                                        class="mb-3">
                                                        <date-picker v-model="filterInput.dateTo" append-to-body
                                                            lang="en" confirm format="YYYY-MM-DD"></date-picker>
                                                    </b-form-group>

                                                </div>
                                            </div>
                                            <div class="col-sm-12 col-md-12">
                                                <div class="alert alert-danger" v-if="filterClicked && !isFormValid"
                                                    style="width:60%">
                                                    Please select "Server" before filtering.
                                                </div>
                                            </div>
                                            <div class="row mt-3">
                                                <div class="col d-inline-flex">
                                                    <button type="button" class="btn btn-success me-2 w-lg"
                                                        @click="submitForm">Filter</button>
                                                    <button type="button" class="btn btn-primary w-lg"
                                                        @click="resetFilters">Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </b-collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body" v-if="showTable">
                        <div class="row mt-4">
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_length" class="dataTables_length">
                                    <label class="d-inline-flex align-items-center">
                                        Show&nbsp;
                                        <b-form-select v-model="perPage" size="sm"
                                            :options="pageOptions"></b-form-select>&nbsp;entries
                                    </label>
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-6">
                                <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                                    <label class="d-inline-flex align-items-center">
                                        Search:
                                        <b-form-input v-model="filter" type="search" placeholder="Search..."
                                            class="form-control form-control-sm ms-2"></b-form-input>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="table-responsive mb-0">
                            <b-table :items="tableData" :busy="isBusy" :fields="fields" responsive="sm"
                                :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                @filtered="onFiltered" show-empty empty-text="No Data Found">
                                <template #table-busy>
                                    <div class="text-center text-black my-2">
                                        <b-spinner class="align-middle text-primary"></b-spinner>
                                        <strong class="text-primary">Loading...</strong>
                                    </div>
                                </template>
                            </b-table>
                        </div>
                        <div class="row mt-3">
                            <div class="col-sm-12 col-md-5">
                                <div class="dataTables_info">
                                    Showing {{ (currentPage - 1) * perPage + 1 }} to
                                    {{ (currentPage - 1) * perPage + rows }} of {{ rows }} entries
                                </div>
                            </div>
                            <div class="col-sm-12 col-md-7">
                                <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage"
                                    align="right" size="sm"></b-pagination>
                            </div>
                        </div>
                    </div>
                    <div v-else class="text-center mt-4 p-4">
                        <strong>Use the filter to display the data</strong>
                    </div>
                </div>
            </div>
        </div>
    </Layout>
</template>
